import React, { useEffect, useState } from "react";
import { Button, Checkbox, Row, Col, Input, Radio, Spin } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../styles/questionnaire.scss";
import logo from "../../images/logo-header.png";
import { makeApiRequest } from "../../helpers/data";
import loadergif from "../../images/loader.gif";
import Modal from "react-bootstrap/Modal";
const Questionnaire = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [countdown, setCountdown] = useState(180);
  const id = JSON.parse(localStorage.getItem("id"));

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(interval);

          //setCountdown(180);
        }

        return prevCountdown - 1;
      });
    }, 1000);

    // Clear the interval when the component unmounts

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const questionsKeys = Object.keys(questions);
    if (questionsKeys.length > 0) return;

    const promise = new Promise((resolve, reject) => {
      makeApiRequest([`llm_eng/getQuestionnaire/${id}`], "GET")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
    promise.then((res) => {
      if (res?.response?.status === 200) {
        setQuestions(res?.data);
        setLoading(false);

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  }, [loading, id, questions, setLoading]);

  const handleCheckboxChange = (question, option) => {
    setAnswers((prevAnswers) => {
      const selectedOptions = [...(prevAnswers[question] || [])];

      if (selectedOptions.includes(option)) {
        if (option === "Other") {
          setOtherAnswers((prevOtherAnswers) => {
            const updatedOtherAnswers = { ...prevOtherAnswers };
            delete updatedOtherAnswers[question];
            return updatedOtherAnswers;
          });
        }
        const updatedOptions = selectedOptions.filter(
          (selected) => selected !== option
        );
        return { ...prevAnswers, [question]: updatedOptions };
      } else {
        const updatedOptions = [...selectedOptions, option];
        return { ...prevAnswers, [question]: updatedOptions };
      }
    });
  };

  const handleRadioChange = (question, option) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [question]: option }));
  };

  const handleNext = () => {
    setSaving(true);
    const promise = new Promise((resolve, reject) => {
      const updatedAnswers = { ...answers };

      const otherQuestions = Object.keys(otherAnswers);
      if (otherQuestions && otherQuestions.length > 0) {
        // replace the 'Other' option with the user's response and save it in updatedAnswers
        otherQuestions.forEach((question) => {
          const otherOptionIndex = updatedAnswers[question].indexOf("Other");
          updatedAnswers[question][otherOptionIndex] = otherAnswers[question];
        });
      }

      makeApiRequest(["llm_eng/scopingQuestionnaireResponses"], "POST", {
        user_id: id,
        user_responses: JSON.stringify(updatedAnswers),
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
    promise
      .then((res) => {
        if (res?.response?.status === 200) {
          // message.success("Questionnaire Saved!");
          setTimeout(async () => {
            try {
              const response = await makeApiRequest(
                ["llm_eng/finalQuestionnaire"],
                "POST",
                {
                  user_id: id,
                  response: JSON.stringify({}),
                }
              );

              if (response?.data) {
                navigate("/questionnaireII", {
                  state: { ques: response?.data?.response },
                });
                // setQuestions(response.data);
                // setLoading(false);
              }
            } catch (error) {
              console.error("Error fetching initial question:", error);
            }
          }, 3000);
        }
      })
      .catch((error) => {
        setSaving(false);
      });
  };

  const handlePrev = () => {
    navigate("/summary")
  }

  const handleRemoveQuestion = (question) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      delete updatedQuestions[question];
      return updatedQuestions;
    });

    setAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };
      delete updatedAnswers[question];
      return updatedAnswers;
    });
  };

  const handleAddQuestion = () => {
    setModalVisible(true);
  };

  const handleSaveQuestion = () => {
    if (newQuestion.trim() !== "" && newOptions.length > 0) {
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        [newQuestion]: { options: newOptions, type: "multiple" }, // Default type set to multiple
      }));
      setModalVisible(false);
      setNewQuestion("");
      setNewOptions([]);
    }
  };

  const handleAddOption = () => {
    setNewOptions((prevOptions) => [...prevOptions, ""]);
  };

  const handleOptionChange = (index, value) => {
    setNewOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = value;
      return newOptions;
    });
  };

  const isAllQuestionsAnswered = () => {
    const questionKeys = Object.keys(questions);
    for (let i = 0; i < questionKeys.length; i++) {
      const question = questionKeys[i];
      const questionType = questions[question].type;
      if (questionType === "multiple") {
        if (!answers[question] || answers[question].length === 0) {
          return false;
        }
      } else if (questionType === "single") {
        if (!answers[question]) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <header className="veriti-header">
        <Link to="/welcome"><img className="img-fluid" src={logo} /></Link>
        <ul className="veriti-stepper">
          <li className="active">
            <span>1</span>
            <h5>Company Summary</h5>
          </li>
          <li className="active">
            <span>2</span>
            <h5>Questionnaire 1</h5>
          </li>
          <li>
            <span>3</span>
            <h5>Questionnaire 2</h5>
          </li>
          <li>
            <span>4</span>
            <h5>Cybersecurity Report</h5>
          </li>
        </ul>
      </header>

      {loading ? (
        <Modal show={loading} centered>
          <Modal.Body>
            <h3>Generating Questionnaire</h3>
            <p>Please wait...</p>
            <h5>
              {countdown}
              <span> sec</span>
            </h5>
            <img
              src={loadergif}
              alt="success"
              className="img-fluid success-gif loader-gif"
            />
          </Modal.Body>
        </Modal>
      ) : saving ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          <Modal show={true} centered>
            <Modal.Body>
              <h3>Generating Questionnaire</h3>
              <p>Please wait...</p>
              <h5>
                {countdown}
                <span> sec</span>
              </h5>
              <img
                src={loadergif}
                alt="success"
                className="img-fluid success-gif loader-gif"
              />
            </Modal.Body>
          </Modal>
          <p style={{ marginTop: "13px" }}>
            Analyzing and generating new question
          </p>
        </div>
      ) : (
        <div className="veriti-section-wrapper veriti-questionary-wrapper">
          <div className="veriti-section-inner">
            <div className="summary_head">
              <h5 className="ant-typography css-dev-only-do-not-override-p7e5j5">
                Finish answering all the questions in order to move on.
              </h5>
            </div>

            {Object.keys(questions)?.map((question, index) => (
              <div className="content-box p-0">
                <div key={index} className="editable-summary-container">
                  <div className="summary-content-head">
                    <h3>
                      <span>{index + 1}</span> {question}
                    </h3>
                  </div>
                  {/* <Checkbox.Group
                    value={answers[question] || []}
                    onChange={(checkedValues) =>
                      setAnswers({ ...answers, [question]: checkedValues })
                    }
                  >
                    <>
                      <Col>
                        {questions[question].options?.map(
                          (option, optionIndex) => (
                            <>
                              <Row key={optionIndex} span={8}>
                                <Checkbox
                                  value={option}
                                  onChange={() =>
                                    handleCheckboxChange(question, option)
                                  }
                                >
                                  <label>{option}</label>
                                </Checkbox>
                              </Row>
                            </>
                          )
                        )}
                      </Col>
                      <Col className="text-area-col">
                        {questions[question].options?.map(
                          (option, optionIndex) =>
                            option === "Other" && (
                              <Row key={optionIndex} span={8}>
                                {answers[question]?.includes("Other") && (
                                  <>
                                    <label>Please Specify</label>
                                    <Input.TextArea
                                      placeholder="Start writing here..."
                                      value={otherAnswers[question] || ""}
                                      maxLength={500}
                                      onChange={(e) => {
                                        setOtherAnswers((prev) => ({
                                          ...prev,
                                          [question]: e.target.value,
                                        }));
                                      }}
                                    />
                                  </>
                                )}
                              </Row>
                            )
                        )}
                      </Col>
                    </>
                  </Checkbox.Group>
                  {questions[question].type === "multiple" ? (
                    <Checkbox.Group
                      value={answers[question] || []}
                      onChange={(checkedValues) =>
                        setAnswers({ ...answers, [question]: checkedValues })
                      }
                    >
                      <>
                        <Col>
                          {questions[question].options?.map(
                            (option, optionIndex) => (
                              <>
                                <Row key={optionIndex} span={8}>
                                  <Checkbox
                                    value={option}
                                    onChange={() =>
                                      handleCheckboxChange(question, option)
                                    }
                                  >
                                    <label>{option}</label>
                                  </Checkbox>
                                </Row>
                              </>
                            )
                          )}
                        </Col>
                        <Col className="text-area-col">
                          {questions[question].options?.map(
                            (option, optionIndex) =>
                              option === "Other" && (
                                <Row key={optionIndex} span={8}>
                                  {answers[question]?.includes("Other") && (
                                    <>
                                      <label>Please Specify</label>
                                      <Input.TextArea
                                        placeholder="Start writing here..."
                                        value={otherAnswers[question] || ""}
                                        maxLength={500}
                                        onChange={(e) => {
                                          setOtherAnswers((prev) => ({
                                            ...prev,
                                            [question]: e.target.value,
                                          }));
                                        }}
                                      />
                                    </>
                                  )}
                                </Row>
                              )
                          )}
                        </Col>
                      </>
                    </Checkbox.Group>
                  ) : (
                    <Radio.Group
                      value={answers[question] || ""}
                      onChange={(e) =>
                        handleRadioChange(question, e.target.value)
                      }
                    >
                      <Col>
                        {questions[question].options?.map(
                          (option, optionIndex) => (
                            <Row key={optionIndex} span={8}>
                              <Radio value={option}>
                                <label>{option}</label>
                              </Radio>
                            </Row>
                          )
                        )}
                      </Col>
                      <Col className="text-area-col">
                        {questions[question].options?.map(
                          (option, optionIndex) =>
                            option === "Other" && (
                              <Row key={optionIndex} span={8}>
                                {answers[question]?.includes("Other") && (
                                  <>
                                    <label>Please Specify</label>
                                    <Input.TextArea
                                      placeholder="Start writing here..."
                                      value={otherAnswers[question] || ""}
                                      maxLength={500}
                                      onChange={(e) => {
                                        setOtherAnswers((prev) => ({
                                          ...prev,
                                          [question]: e.target.value,
                                        }));
                                      }}
                                    />
                                  </>
                                )}
                              </Row>
                            )
                        )}
                      </Col>
                    </Radio.Group>
                  )} */}
                  {Array.isArray(questions[question]) ? (
                    <Checkbox.Group
                      value={answers[question] || []}
                      onChange={(checkedValues) =>
                        setAnswers({ ...answers, [question]: checkedValues })
                      }
                    >
                      <Col>
                        {questions[question].map((option, optionIndex) => (
                          <Row key={optionIndex} span={8}>
                            <Checkbox
                              value={option}
                              onChange={() =>
                                handleCheckboxChange(question, option)
                              }
                            >
                              <label>{option}</label>
                            </Checkbox>
                          </Row>
                        ))}
                      </Col>
                      <Col className="text-area-col">
                        {questions[question].map((option, optionIndex) => (
                          <Row key={optionIndex} span={8}>
                            {option === "Other" &&
                              answers[question]?.includes("Other") && (
                                <>
                                  {" "}
                                  <label>Please Specify</label>
                                  <Input.TextArea
                                    placeholder="Start writing here..."
                                    value={
                                      otherAnswers[question]
                                        ? otherAnswers[question]
                                        : ""
                                    }
                                    maxLength={500}
                                    onChange={(e) => {
                                      setOtherAnswers((prevOtherAnswers) => ({
                                        ...prevOtherAnswers,
                                        [question]: e.target.value,
                                      }));
                                    }}
                                  />
                                </>
                              )}
                          </Row>
                        ))}
                      </Col>
                    </Checkbox.Group>
                  ) : (
                    <p>Please provide options for this question.</p>
                  )}
                </div>
              </div>
            ))}

            <div className="content-box p-0">
              <div className="button-container">
                <div className="btn-wrapper border-0">
                  <button
                    type="button"
                    className="bg-white text-dark ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                    onClick={handlePrev}
                  >
                    <span className="prev"></span>
                    Prev
                  </button>
                </div>
                <button
                  type="button"
                  className=" text-white ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                  onClick={handleNext}
                >
                  <span>Next </span>
                  <span className="continue"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Add New Question"
        visible={modalVisible}
        onOk={handleSaveQuestion}
        onCancel={() => setModalVisible(false)}
      >
        <Input
          placeholder="Enter question"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
        />
        <div>
          <p>Options:</p>
          {newOptions?.map((option, index) => (
            <Row key={index} span={8}>
              <Input
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
              />
            </Row>
          ))}
          <Button onClick={handleAddOption}>Add Option</Button>
        </div>
      </Modal>
    </>
  );
};

export default Questionnaire;
