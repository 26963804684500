import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import "antd/dist/reset.css";
import "../../styles/initiateLogin.scss";
import { isValidEmail } from "../../helpers/utils";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo.png";
import { Form } from "react-bootstrap";
const ClearData = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      message.error("Invalid Email Address");
      return;
    }

    // perfrom API call to check user credentials
    const promise = new Promise((resolve, reject) => {
      makeApiRequest(["data/deleteUserDataByEmail"], "POST", {
        email,
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

    promise.then((res) => {
      if (res?.response?.status === 200) {
        localStorage.setItem("organizationName", res?.data?.organization_name);
        navigate("/", {
          state: { organizationName: res?.data?.organization_name, username: res?.data?.user_name },
        });
        localStorage.removeItem("id")
      } else {
        if (res?.error?.response?.data?.detail) {
          message.error(res?.error?.response?.data?.detail);
        }
      }
    });
  };

  return (
    <div className="login-container veriti_login--wrapper">
      <div className="veriti_login--inner">
      <h1><img src={logo} alt="logo" className="img-fluid" /></h1>
      <h3>Remove User Data</h3>
      <div className="form-container">
      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                placeholder="Enter Email ID"
                type="email"
               onChange={handleEmailChange} className="input-field" 
              />
              </Form.Group>

        <Button type="primary" onClick={handleSubmit} className="submit-button btn-veriti">
          Submit
        </Button>
      </div>
      </div>
    </div>
  );
};

export default ClearData;
