import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import succesgif from "../../images/success.gif";
import { useNavigate } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const PaymentModal = ({ show, handleClose, reportType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPayButton, setShowPayButton] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    country: "",
    zip: "",
    name: "",
  });
  const [successShow, setSuccessShow] = useState(false);
  const [cardInfo, setCardInfo] = useState(null); // To store retrieved card information
  const [cardhide, setCardHide] = useState(false); // To store retrieved card information
  const navigate = useNavigate();
  const userId = localStorage.getItem("id").replace(/"/g, ""); // Retrieve userId from localStorage

  useEffect(() => {
    if (show) {
      // Call the API to get card details when modal is opened
      axios
        .get(`http://64.23.148.248:8000/llm_eng/getCard/${userId}`)
        .then((response) => {
          setCardInfo(response.data); // Set the card details if available
          setCardHide(true);
        })
        .catch((error) => {
          console.error("Error fetching card details:", error);
          setCardInfo(null); // If no card found, set it to null
          setCardHide(false);
        });
    }
  }, [show, userId]);

  const handleInputChange = (e) => {
    setBillingDetails({
      ...billingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuccessClose = () => {
    setSuccessShow(false);
    navigate("/next-page");
  };

  const recommendationId = 94;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    let paymentMethodId;

    // Check if the user selected the stored card
    if (cardInfo && cardhide) {
      // Use the existing payment method ID
      paymentMethodId = cardInfo.payment_method_id;
    } else {
      // Create a new token using Stripe elements
      const cardElement = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardElement, {
        name: billingDetails.name,
        address_zip: billingDetails.zip,
        address_country: billingDetails.country,
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
        return;
      }
      paymentMethodId = token.id; // New token for payment
    }

    const storedEmail = localStorage.getItem("email");
    const payload = {
      payment_method_token: paymentMethodId,
      email: storedEmail,
      user_id: userId,
    };

    axios
      .post("http://64.23.148.248:8000/llm_eng/create-payment-method", payload)
      .then((response) => {
        console.log("Payment method created successfully:", response.data);
        setIsProcessing(false);
        handleClose();
        setSuccessShow(true);
        if(cardInfo){
          handleCardDelete(cardInfo.card_id)
        }
        setTimeout(() => {
          if (reportType) {
            navigate(`/finalSummaryII/${recommendationId}`);
          } else {
            navigate(`/finalSummaryIII`);
          }
        }, 3000);
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.detail ||
            error.message ||
            "Failed to create payment method."
        );
        setIsProcessing(false);
      });
  };

  const submitPayment = () => {
    setIsProcessing(false);
    handleClose();
    setSuccessShow(true);

    setTimeout(() => {
      if (reportType) {
        navigate(`/finalSummaryII/${recommendationId}`);
      } else {
        navigate(`/finalSummaryIII`);
      }
    }, 3000);
  }

  const cardStyle = {
    base: {
      background:
        "linear-gradient(0deg, rgba(67, 97, 238, 0.03), rgba(67, 97, 238, 0.03)), linear-gradient(0deg, #ffffff, #ffffff)",
      border: "2px solid #4361ee33",
      padding: "20px",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "18px",
      textAlign: "left",
      color: "#333333",
      borderRadius: "10px",
      "::placeholder": {
        color: "#999999", // optional styling for placeholders
      },
    },
    invalid: {
      color: "#ff0000",
    },
  };

  const handleCardDelete = (cardId) => {
    axios
      .delete(
        `http://64.23.148.248:8000/llm_eng/deleteCard/${cardId}/${userId}`
      )
      .then(() => {
        console.log("Card deleted successfully.");
        setCardInfo(null);
      })
      .catch((error) => {
        console.error("Error deleting card:", error);
      });
  };

  return (
    <>
      <Modal
        show={show}
        className="payment-modal"
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <h3>Payment Details</h3>
          {cardhide ? (
            <div className="form-group d-flex align-items-center justify-content-between w-100">
              <label className="form-label m-0 ">
                XXXX XXXX {cardInfo?.card_number}{" "}
                <strong> {cardInfo.card_brand}</strong>
              </label>
              <input
                type="radio"
                name="cardSelection"
                className="form-check-input me-3 radio-button-pay"
                onClick={() => {
                  console.log("Selected existing card", cardInfo);
                  setShowPayButton(true); // Show Pay button when radio is clicked
                }}
              />

              {/* <button
                onClick={() => handleCardDelete(cardInfo.card_id)}
                className="btn btn-danger"
              >
                Remove
              </button> */}
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">Name on Card</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="John Doe"
                        className="input-field form-control"
                        value={billingDetails.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">Card Number</label>
                      <CardNumberElement
                        options={{ style: cardStyle }}
                        className="input-field form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Expiry Date</label>
                      <CardExpiryElement
                        options={{ style: cardStyle }}
                        className="input-field form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">CVC</label>
                      <CardCvcElement
                        options={{ style: cardStyle }}
                        className="input-field form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <label className="form-label">Country</label>
                      {/* <select
                        name="country"
                        className="form-select"
                        value={billingDetails.country}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Country</option>
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                        <option value="GB">United Kingdom</option>
                      </select> */}
                        <input
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        className="input-field form-control"
                        value={billingDetails.country}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <label className="form-label">ZIP Code</label>
                      <input
                        type="text"
                        name="zip"
                        placeholder="12345"
                        className="input-field form-control"
                        value={billingDetails.zip}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
              </form>
            </>
          )}
          {showPayButton && cardhide && (
            <Button
              onClick={submitPayment} // Call handleSubmit when clicked
              className="btn btn-veriti w-100 mb-3"
              disabled={isProcessing || !stripe}
            >
              {isProcessing ? "Processing..." : "Pay"}
            </Button>
          )}

          {cardhide && (
            <Button
              onClick={() => {
                setCardHide(false);
              }}
              className="btn btn-veriti  w-100"
            >
              Update Card
            </Button>
          )}
        </Modal.Body>
        {!cardhide && (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleSubmit}
              disabled={isProcessing || !stripe}
            >
              {isProcessing ? "Processing..." : "Submit Payment"}
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={successShow} centered>
        <Modal.Body>
          <h3>
            Payment Done{" "}
            {reportType
              ? "Generate Detailed Recommendation Report"
              : "Create an IT Policy Manual"}
          </h3>
          <p>Please Wait...</p>
          <img
            src={succesgif}
            alt="success"
            className="img-fluid success-gif"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentModal;
