import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, message, Select, Space } from "antd";
import "../../styles/summary.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeApiRequest } from "../../helpers/data";
import html2pdf from "html2pdf.js";
import logo from "../../images/logo-header.png";
import Modal from "react-bootstrap/Modal";
import succesgif from "../../images/success.gif";
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const EditableSummary = () => {
  const { state } = useLocation();
  const { data, id, created_at,edit } = state;
  console.log(edit)
  const isEmptyObject = (obj) =>
    obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  const [editable, setEditable] = useState( edit );
  const [editedSummary, setEditedSummary] = useState(data || {});
  const [orignalSummary, setOriginalSummary] = useState(data || {});
  const [loading, setLoading] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [dropDownLocations, setDropDownLocations] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (editedSummary) {
      const { Industry, Size, Locations } = editedSummary;
      const arrayOfObjects = Industry.split(", ").map((item) => ({
        value: item,
      }));
      const arrayOfSizeObjects = Size.split(", ").map((item) => ({
        value: item,
      }));
      const arrayOfLocationsObjects = Locations.split(", ").map((item) => ({
        value: item,
      }));
      setDropDownOptions(arrayOfObjects);
      setSizeOptions(arrayOfSizeObjects);
      setDropDownLocations(arrayOfLocationsObjects);

    }
      handleEditToggle();
  }, []);

  const handleEditToggle = () => {
    if (editable) {
      // setShowModal(true);
      setOriginalSummary(editedSummary);
    }
    setEditable(!editable);
  };

  const handleSummaryEdit = (key, value) => {
    console.log("🚀 ~ handleSummaryEdit ~ key:", key);
    setEditedSummary({
      ...editedSummary,
      [key]: value,
    });
  };

  const exportToPDF = () => {
    const element = document.getElementsByClassName("summary-item")[0];
    html2pdf(element, {
      margin: 0.75,
      filename: `company-summary-${localStorage.getItem(
        "organizationName"
      )}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    });
  };

  const handleNext = () => {
    const promise = new Promise((resolve, reject) => {
      makeApiRequest(["llm_eng/updateSummary"], "POST", {
        user_id: id,
        summary_dict: JSON.stringify(editedSummary),
      })
        .then((response) => {
          resolve(response)
           // Modal should show here
        
        })
        .catch((error) => reject(error));
    });

    promise.then((res) => {
      if (res?.response?.status === 200) {
        setTimeout(() => {
          handleClose();
          navigate("/questionnaire");
        }, 3000);
      }
    });
  };

  const handleSelectChange = (value, key) => {
    console.log(value);
    let selectedOptions = value;
    if (key === "Industry") {
      selectedOptions = value.join(", ");
    }

    handleSummaryEdit(key, selectedOptions);
  };
  const handlePrint = () => {
    window.print(); // This triggers the browser's print functionality
  };

  const renderSummaryItems = (startKey, endKey) => {
    const keys = Object.keys(editedSummary);
    const startIndex = keys.indexOf(startKey);
    const endIndex = endKey ? keys.indexOf(endKey) + 1 : keys.length;

    return keys.slice(startIndex, endIndex).map((key) => (
      <div key={key} className="summary-item">
        <span className="summary-label">{key}:</span>
        {key === "Social Media Presence" &&
        typeof editedSummary[key] === "object" ? (
          <div className="social-media-container">
            {Object.entries(editedSummary[key]).map(
              ([socialKey, socialValue]) => (
                <div key={socialKey} className="social-media-item">
                  <span className="social-media-label">{socialKey}:</span>
                  {editable ? (
                    <TextArea
                      className="form-control"
                      value={socialValue}
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      onChange={(e) =>
                        handleSummaryEdit(`${key}.${socialKey}`, e.target.value)
                      }
                    />
                  ) : (
                    <Paragraph className="summary-value">
                      {socialValue}
                    </Paragraph>
                  )}
                </div>
              )
            )}
          </div>
        ) : editable ? (
          <>
            {(key === "Industry" || key === "Size" || key === "Locations") && (
              <Select
                mode={key === "Size" ? "" : "multiple"}
                style={{ width: "100%" }}
                defaultValue={
                  key === "Locations"
                    ? dropDownLocations
                    : key === "Industry"
                    ? dropDownOptions
                    : sizeOptions
                }
                onChange={(value) => handleSelectChange(value, key)}
                options={
                  key === "Locations"
                    ? dropDownLocations
                    : key === "Industry"
                    ? dropDownOptions
                    : sizeOptions
                }
              />
            )}
            {key === "Industry" || key === "Size" || key === "Locations" || (
              <TextArea
                className="form-control"
                value={editedSummary[key]}
                autoSize={{ minRows: 1, maxRows: 6 }}
                onChange={(e) => handleSummaryEdit(key, e.target.value)}
              />
            )}
          </>
        ) : (
          <Paragraph className="summary-value">
            {key === "Size" ? (
              editedSummary[key]?.split(",")[0]
            ) : key === "Locations" || key === "Industry" ? (
              <ul className="tab-list">
                {editedSummary[key]?.split(",").map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
            ) : (
              editedSummary[key]
            )}
          </Paragraph>
        )}
      </div>
    ));
  };

  return (
    <>
      <header className="veriti-header">
        <Link to="/welcome"><img className="img-fluid" src={logo} /></Link>
        <ul className="veriti-stepper">
          <li className="active">
            <span>1</span>
            <h5>Company Summary</h5>
          </li>
          <li>
            <span>2</span>
            <h5>Questionnaire 1</h5>
          </li>
          <li>
            <span>3</span>
            <h5>Questionnaire 2</h5>
          </li>
          <li>
            <span>4</span>
            <h5>Cybersecurity Report</h5>
          </li>
        </ul>
      </header>
      <div className="veriti-section-wrapper">
        <div className="veriti-section-inner">
          <div className="summary_head">
            <Title level={2}>Company Summary</Title>
            <Title
              level={5}
              style={{
                marginTop: "-1%",
                fontWeight: "initial",
                marginBottom: "2%",
              }}
            >
              <span>{created_at}</span>
            </Title>
          </div>
          <div className="content-box p-0">
            <div
              className={`editable-summary-container ${
                editable ? "editable" : ""
              }`}
            >
              <div className="summary-content-head">
                <h3>Basic Information</h3>
              </div>
              {loading && <Spin size="large" />}
              {!loading && (
                <div id="content-to-print" className="summary-item">
                  {renderSummaryItems("Size", "Industry")}
                </div>
              )}
            </div>
          </div>
          <div className="content-box p-0">
            <div
              className={`editable-summary-container ${
                editable ? "editable" : ""
              }`}
            >
              <div className="summary-content-head">
                <h3>Additional Information</h3>
              </div>
              {!loading && (
                <div id="additional-info" className="summary-item">
                  {renderSummaryItems("Domiciled", null)}
                </div>
              )}
            </div>
          </div>
          <div className="content-box p-0">
            {isEmptyObject(data) && <p>No Summary Available.</p>}
            <div className="button-container">
              <div className="btn-wrapper">
                {!isEmptyObject(data) && (
                  <>
                   <Button
                      type="primary"
                      className="btn-veriti"
                      onClick={handleEditToggle}
                    >
                      {editable ? (
                        "Save"
                      ) : (
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          Edit Information
                          <span className="edit"></span>
                        </div>
                      )}
                    </Button>
                    {editable && (
                      <Button
                        type="primary"
                        className="btn-veriti"
                        onClick={() => {
                          setEditable(false);
                          setEditedSummary(orignalSummary);
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </>
                )}

                <Button
                  type="primary"
                  className="btn-veriti"
                  title=" Download the Full Report"
                  onClick={exportToPDF}
                >
                  Download <span className="download"></span>
                </Button>

                <Button
                  type="primary"
                  className="btn-veriti"
                  title=" Print"
                  onClick={handlePrint}
                >
                  Print <span className="print"></span>
                </Button>
              </div>

              <Button
                type="primary"
                onClick={handleNext}
                className="btn-veriti"
              >
                Continue <span className="continue"></span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <h3>Success!</h3>
          <p>Summary Saved Successfully!</p>
          <img
            src={succesgif}
            alt="success"
            className="img-fluid success-gif"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditableSummary;
