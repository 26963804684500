import React, { useEffect, useRef, useState } from "react";
import { Spin, message } from "antd";
import { Spinner } from "react-bootstrap";
import "../../styles/welcome.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo-header.png";
import alert from "../../images/alert.png";
import delete_logo from "../../images/delete.png";
import hi from "../../images/hi.png";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const Welcome = ({ reportHandler }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [SpinnerLoader, setSpinnerLoader] = useState(true);
  // Assuming 'state' is your state object
  const { organizationName: stateOrgName, username: stateUsername } =
    state || {};

  // Retrieve values from local storage
  const organizationName =
    localStorage.getItem("organizationName") || stateOrgName;
  const username = localStorage.getItem("username") || stateUsername;
  const [reportList, setReportList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttonText: "",
  });
  const [Message, setMessage] = useState(
    `Please click the Start button to begin the Cybersecurity Analysis of <strong>${
      organizationName || "Default Organization"
    }</strong>.`
  );
  const [stepMessages, setStepMessages] = useState([]);
  const [statusSummary, setStatusSummary] = useState(false);

  const intervalIdRef = useRef(null);

  // Store organizationName and username in localStorage
  useEffect(() => {
    if (organizationName && username) {
      localStorage.setItem("organizationName", organizationName);
      localStorage.setItem("username", username);
    }
  }, [organizationName, username]);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true); // Step 2: Set loading to true before fetching
      const id = JSON.parse(localStorage.getItem("id"));
      const url1 = `http://64.23.148.248:8000/llm_eng/CheckSummary/${id}`;
      const url2 = `http://64.23.148.248:8000/llm_eng/getAllReports/${id}`;

      try {
        const response1 = await axios.get(url1);
        console.log("First API Response:", response1.data);
        setStatusSummary(true);

        const response2 = await axios.get(url2);
        console.log("Second API Response:", response2.data);
        setReportList(response2.data);
      } catch (error) {
        console.error("Error calling API:", error);
        setStatusSummary(false);
      } finally {
        setLoading(false); // Step 3: Set loading to false after fetching
      }
    };

    callApi();
  }, [navigate]);

  const fetchData = async ({ edithandle = true } = {}) => {
    const id = JSON.parse(localStorage.getItem("id"));
    const apiEndpoint = [`llm_eng/orgSummary/${id}`];

    try {
      const response = await makeApiRequest(apiEndpoint, "GET");
      const responseData = response?.response?.data;
      const { status, step, total_steps, message } = responseData;

      if (
        status === "SCRAPE_WEBSITE" ||
        status === "GET_DNS_SSL_INFO" ||
        status === "SUMMARIZE" ||
        status === "GET_DMARK_INFO"
      ) {
        setStepMessages((prevMessages) => [...prevMessages, message]);
        setMessage(`${stepMessages.join(" ")}`);
      } else if (status === "COMPLETE") {
        setMessage("Data has been fetched successfully!");
        clearInterval(intervalIdRef.current);
        navigate("/summary", {
          state: {
            data: responseData?.summary_dict,
            id: JSON.stringify(id),
            created_at: responseData?.created_at,
            edit: edithandle,
          },
        });
      } else if (status === "FAILED") {
        setMessage(`Error: ${message}. Please try again.`);
        clearInterval(intervalIdRef.current);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setMessage(
        `Error fetching data for ${
          organizationName || "Default Organization"
        }. Please try again.`
      );
    }
  };

  const handleClick = () => {
    setLoading(true);
    fetchData(true);
    intervalIdRef.current = setInterval(() => {
      fetchData();
    }, 7000);
  };

  const generateSummaryHandler = () => {
    fetchData(true);
  };

  const handleDeleteReport = async (recommendationId) => {
    const url = `http://64.23.148.248:8000/llm_eng/deleteReport/${recommendationId}`;

    try {
      const response = await axios.delete(url);
      console.log("Delete API Response:", response.data);
      setReportList((prevReports) =>
        prevReports.filter(
          (report) => report.recommendation_id !== recommendationId
        )
      );
      message.success("Report deleted successfully!");
    } catch (error) {
      console.error("Error deleting report:", error);
      message.error("Failed to delete report. Please try again.");
    }
  };

  const handleModalConfirm = () => {
    if (modalContent.buttonText === "Delete") {
      handleDeleteReport(modalContent.recommendationId);
    }
    handleClose();
  };

  const handleShow = (title, description, buttonText, recommendationId) => {
    setModalContent({ title, description, buttonText, recommendationId });
    setShow(true);
  };

  return (
    <div>
      <header className="veriti-header">
        <Link to="/welcome">
          <img className="img-fluid" src={logo} />
        </Link>
      </header>
      <div className="veriti-section-wrapper">
        <div className="veriti-section-inner">
          <div className="content-box p-0">
            <div className="start-button-container">
              <div className="welcome-message">
                <img src={hi} alt="hi" className="img-fluid" />
                <p>
                  Welcome <span>{username || "Guest"}</span>,{" "}
                  <span>at {organizationName || "Default Organization"}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="content-box">
            <ul className="summary-list">
              <li>
                <h4>(Re)Generate Company Summary</h4>
                <Button className="btn-veriti" onClick={generateSummaryHandler}>
                  Generate
                </Button>
              </li>
              <li>
                <h4>View Company Summary</h4>
                <Button
                  className={`btn-veriti ${
                    statusSummary ? "" : "disabled-btn"
                  }`}
                  onClick={() => fetchData()}
                >
                  View
                </Button>
              </li>
              <li>
                <h4>(Optional) Edit Company Profile</h4>
                <Button
                  className={`btn-veriti ${
                    statusSummary ? "" : "disabled-btn"
                  }`}
                  onClick={() => fetchData({ edithandle: false })}
                >
                  Edit
                </Button>
              </li>
              <li>
                <h4>Open-Source Intelligence Gathering</h4>
                <Button className="btn-veriti disabled-btn">Gather</Button>
              </li>
            </ul>
          </div>
          <div className="content-box">
          <div className="table-container">
  {loading ? (
    <div className="text-center mb-4">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : reportList.length === 0 ? (
    <div className="text-center">
      <p>No reports available.</p>
    </div>
  ) : (
    <Table>
      <tbody>
        {reportList.map((e) => (
          <React.Fragment key={e.recommendation_id}>
            <tr>
              <th>Cybersecurity Report</th>
              <td>{e?.created_at}</td>
              <td>
                <div className="btn_wrapper">
                  <Button
                    className="btn-veriti"
                    onClick={() => {
                      reportHandler(e);
                      navigate("/finalSummary");
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() =>
                      handleShow(
                        "Delete?",
                        "Are you sure you want to delete?",
                        "Delete",
                        e?.recommendation_id
                      )
                    }
                    className="btn-veriti"
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
            {e.responses &&
              e.responses.map((data) => (
                <tr key={data.response_id}>
                  <th>
                    <span className="bottom-right">Recommendation Report</span>
                  </th>
                  <td>{data.created_at}</td>
                  <td>
                    <div className="btn_wrapper d-flex align-items-center justify-content-center">
                      <Button
                        className="btn-veriti w-100"
                        onClick={() => {
                          reportHandler(data);
                          navigate(`/finalSummaryII/${data.response_id}`);
                        }}
                      >
                        View
                      </Button>
                      <Button
                        className="btn-veriti d-none w-100"
                        onClick={() => {
                          reportHandler(data);
                          navigate(`/finalSummaryII/${data.response_id}`);
                        }}
                      >
                        View
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  )}
</div>


            <Button className="btn-veriti btn-bottom" onClick={handleClick}>
              Generate New Cybersecurity Report
            </Button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <img
            src={modalContent.buttonText === "Delete" ? delete_logo : alert}
            className="img-fluid"
            alt="Alert"
          />
          <h3>{modalContent.title}</h3>
          {modalContent.description && <p>{modalContent.description}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`${
              modalContent.buttonText === "Delete" ? "btn-danger" : ""
            }`}
            variant="secondary"
            onClick={handleModalConfirm}
          >
            {modalContent.buttonText}
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Welcome;
